Vue.component('customerGiftsListComponent', {
    data: function () {
        return {
            itemsFlag:false,
            orderSelected:null,
            GiftsItemList : []
        }
    },
    watch: {
        '$store.state.appState':'fetchData',
        '$store.state.customerGifts':'fetchData',
        '$store.state.orderBy':'fetchData',
        '$store.state.orderDirection':'fetchData'
    },
    computed:{
        viewmode: function(){
            return this.$store.getters.getItemViewMode;
        },
        initItem:function(){
            return this.$store.getters.getItemPerPage*this.$store.getters.getOffset;
        },
        lastItem:function(){
            return this.$store.getters.getItemPerPage*this.$store.getters.getOffset + this.$store.getters.getItemPerPage;
        },
        moduleObject: function () {
            return {
                'green-text': this.viewmode == 'module'
            }
        },
        listObject: function () {
            return {
                'green-text': this.viewmode == 'list'
            }
        }
    },
    methods:{
        fetchData:function(){
            if(!this.orderSelected)
                for(let orderField of this.$store.getters.getSetting.ItemOrdered){
                    if(orderField.fields.ByDefault){
                        this.orderSelected = orderField.fields.internalId
                    }
                }

            this.GiftsItemList=[]
            for(let itemCode of this.$store.getters.getCustomerGifts){
                let itemLoad = this.$store.getters.getItemByCode(itemCode);
                if(itemLoad)
                    this.GiftsItemList.push(itemLoad)
            }
            this.GiftsItemList = __lodash__.orderBy(this.GiftsItemList, this.$store.getters.getOrderBy,this.$store.getters.getOrderDirection);
        },
        openItemView:function(item){
            if(item)
                this.$router.push(`/view/${item.Code}`);
        },
        reorderItems: function(){
            this.$store.dispatch('reorderItems',this.orderSelected);
            //this.GiftsItemList = __lodash__.orderBy(this.GiftsItemList,this.$store.state.orderBy,'asc');
        },
        getItemByCode:function(itemCode){
            return this.$store.getters.getItemByCode(itemCode);
        },
        changeViewMode:function(viewMode){
            this.$store.dispatch('changeItemViewMode',viewMode)
        }
    },
    mounted:function(){
        this.fetchData()
    },
    template: `<div class="produktliste">
                    <div class="header-produktliste">
                        <h4>{{tr('Customer Gifts')}} ({{GiftsItemList.length}})</h4>
                        <div class="items-options">
                            <div class="order-options">
                                <span>{{tr('Order by')}}</span>
                                <select id="soflow" v-model="orderSelected" class="minimal browser-default" @change="reorderItems">
                                    <option disabled value="">{{tr('Select One')}}</option>
                                    <template v-for="orderField of $store.getters.getSetting.ItemOrdered">
                                        <option :value="orderField.fields.internalId" :selected="orderField==orderSelected">{{tr(orderField.fields.ItemField)}}({{orderField.fields.OrderDirection}})</option>
                                    </template>
                                </select>
                            </div>
                            <div class="view-options">
                                <span @click="changeViewMode('module')" v-bind:class="moduleObject">
                                    <span>Mosaico</span>
                                    <i class="fas fa-th-large fa-1x"></i>
                                </span>
                                <span @click="changeViewMode('list')" v-bind:class="listObject">
                                    <span >Lista</span>
                                    <i class="fas fa-list-alt fa-1x"></i>
                                </span>
                            </div>
                            <template v-if="GiftsItemList.length>0">
                                <paginationComponent currentView="customerGifts" :items="GiftsItemList" v-if="GiftsItemList.length>0"></paginationComponent>
                            </template>
                        </div>
                        <div class="produktliste">
                             <template v-if="$store.getters.getAppState!='ready'">
                                    <template v-if="GiftsItemList.length==0">
                                        <div class="container center section white" >
                                            <h3>{{tr('Please wait. Loading ...')}}</h3>
                                        </div>
                                    </template>
                             </template>
                            <template v-else-if="GiftsItemList.length>0">
                                <template v-for="item in GiftsItemList.slice(initItem,lastItem)">
                                    <div :class="{col:true, 's12': viewmode=='list', 's6 m6 l3': viewmode=='module'}" >
                                          <template v-if="viewmode=='list'">
                                              <itemViewListComponent :item="item" :stock="true" ></itemViewListComponent>
                                          </template>
                                          <template v-else>
                                              <itemViewModuleComponent :item="item" :stock="true" ></itemViewModuleComponent>
                                          </template>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="GiftsItemList.length==0">
                                    <div class="container center section white" >
                                        <h3>{{tr('No items was found')}}</h3>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="items-options">
                            <template v-if="GiftsItemList.length>0">
                                <paginationComponent currentView="customerGifts" :items="GiftsItemList" v-if="GiftsItemList.length>0"></paginationComponent>
                            </template>
                        </div>
                    </div>
                </div>`
});
